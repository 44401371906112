import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";

function HeroSection() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <div className="pic-wrapper">
        <figure
          className="pic-1"
          style={{ background: `url("${rpdata?.gallery?.[10]}")` }}
        ></figure>
        <figure
          className="pic-2"
          style={{ background: `url("${rpdata?.gallery?.[13]}")` }}
        ></figure>
        <figure
          className="pic-3"
          style={{ background: `url("${rpdata?.gallery?.[17]}")` }}
        ></figure>
        <figure
          className="pic-4"
          style={{ background: `url("${rpdata?.gallery?.[15]}")` }}
        ></figure>
        <div className="z-[9] relative w-4/5 mx-auto md:h-[100vh] h-[80vh] pt-0">
          <div className="w-full h-full pb-[20%] md:pb-[10%] flex flex-col justify-end items-center">
            <h1 className="text-white text-center text-[70px]">
              {rpdata?.dbSlogan?.[10].slogan.substring(0, 26)}
            </h1>
            <h2 className="text-white text-center text-[35px] ">
              {rpdata?.dbSlogan?.[10].slogan.substring(27)}
            </h2>
            {/* <p className="text-white text-center"> {rpdata?.dbSlogan?.[8].slogan}</p> */}
            <div className="flex justify-center md:block">
              <ButtonContent />
            </div>
          </div>
          <div className="w-1/2"></div>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
